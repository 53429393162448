<template>
  <div id="basicLayout">
    <a-layout style="height: 100vh">
      <a-alert banner center v-if="showLoading">
        注意第一次加载慢后台正在启动中
      </a-alert>
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
        <a-watermark content="徐煜程">
          <div style="width: 100%; height: 50px" />
        </a-watermark>
      </a-layout-content>
      <a-layout-footer class="footer">
        <a href="https://www.github.com/Xuyuyu520" target="_blank">
          <img style="height: 16px" />
          <span><icon-github /> author by Xuyuyu520</span>
        </a>
        <icon-minus />
        <icon-minus />
        <span><icon-exclamation /></span>
        <a href="https://home-alpha-ten.vercel.app/" target="_blank"
          >我的开发主页</a
        >
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from "vue";
import GlobalHeader from "@/components/GlobalHeader.vue";

const showLoading = ref(true);

onMounted(() => {
  nextTick(() => {
    showLoading.value = false;
  });
});
</script>

<style scoped>
#basicLayout {
}

.header {
  margin-bottom: 18px;
  box-shadow: blanchedalmond 5px 5px 10px;
}

.content {
  margin-bottom: 20px;
  padding: 20px;
  background: linear-gradient(to right, #fefefe, #fff);
}

.footer {
  padding: 16px;
  text-align: center;
  background: #f0f2f5;
}
</style>
