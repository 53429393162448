<template>
  <div>
    <customCalendar
      showPrepNext
      :startYearMonth="startYearMonth"
      :markDate="markDate"
      :checkedDate="checkedDate"
      @clickDate="clickDate"
      @changeMonth="changeMonth"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import customCalendar from "../add/components/CustomCalendar.vue";
import {
  addUserSignUsingPost,
  getUserSignVoByIdUsingGet,
} from "@/api/userSignController";
import API from "@/api";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";

const markDate = ref<string[]>([]);
const checkedDate = ref(new Date().toISOString().slice(0, 10));
const startYearMonth = ref(new Date().toISOString().slice(0, 7));
const today = new Date().toISOString().slice(0, 10);

// 状态相关
const showCheckedStatus = ref(false);
const checkedStatus = ref("");

const clickDate = async (date: string) => {
  console.log("Event received in parent component:", date); // 日志
  const clickedDate = new Date(date);
  const currentDate = new Date(today);

  // 点击日期大于今天，直接返回
  // 点击日期大于今天，或者小于今天，直接返回
  if (clickedDate > currentDate || clickedDate < currentDate) {
    return;
  }

  // 更新选中的日期
  checkedDate.value = date;

  // 添加签到数据
  addSignData.value.signDate = date;
  await addSignIn();

  // 更新签到状态
  updateCheckedStatus();
};

const changeMonth = (date: string) => {
  console.log(date);
};

const loginUserStore = useLoginUserStore();
const userId = loginUserStore.loginUser?.id;
const form = ref<API.UserSignVO>({
  signDate: "",
  user: "",
  userId: userId,
});

// 加载签到数据
const loadSignInData = async () => {
  if (!form.value.userId) {
    return;
  }
  try {
    const res = await getUserSignVoByIdUsingGet({
      id: loginUserStore.loginUser.id as any,
    });
    if (res.data.code === 0) {
      await loginUserStore.fetchLoginUser();
      message.success("查询签到记录成功");

      // 将标记的日期转换为 yyyy-MM-dd 格式
      markDate.value = res.data.data
        .map((record: string) => {
          const signDate = new Date(record);
          if (isNaN(signDate.getTime())) {
            console.error("Invalid date string:", record);
            return null;
          }
          return signDate.toISOString().slice(0, 10);
        })
        .filter(Boolean);

      // 更新签到状态
      updateCheckedStatus();
    } else {
      message.error(res.data.message as string);
    }
  } catch (error) {
    message.error("加载签到记录失败");
  }
};

// 添加签到数据
const addSignData = ref<API.UserSignAddRequest>({
  signDate: "",
  userId: userId,
});

const addSignIn = async () => {
  console.log("Add Sign In called with date:", addSignData.value.signDate); // 日志
  if (!addSignData.value.userId) {
    return;
  }
  try {
    const res = await addUserSignUsingPost(addSignData.value);
    if (res.data.code === 0) {
      message.success("签到成功");

      // 更新签到状态
      updateCheckedStatus();
    } else {
      message.error(res.data.message as string);
    }
  } catch (error) {
    message.error("加载签到记录失败");
  }
};

// 更新签到状态
const updateCheckedStatus = () => {
  showCheckedStatus.value = true;
  checkedStatus.value = markDate.value.includes(checkedDate.value)
    ? "已签到"
    : "未签到";
};

// 在 mounted 阶段执行自动签到
let signedOnce = false; // 标记是否已经自动签到过

onMounted(() => {
  loadSignInData();

  if (!signedOnce) {
    addSignData.value.signDate = today;
    addSignIn();
    signedOnce = true; // 标记为已自动签到
  }
});
</script>

<style lang="scss" scoped>
.m-calendar {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
</style>
